<template lang="html">
  <div :class="{'d-flex':true, 'flex-column':true, 'event-card-option':true, 'has3games': event.games.length == 3}">
      <b-btn v-if="viewType==='vote'"
        @click.prevent="onVoting(option)"
        :variant="option.voted ? 'info' : 'outline-info'"
        :disabled="disableButton()"
      >
        <div class="text-truncate">{{option.title}}</div>
      </b-btn>

      <b-btn v-else @click.prevent="callBetslip(option)"
        :variant="option.isInBetslip ? 'warning' : 'dark'"
        :class="BetButtonClassPrefix + option.id"
        :disabled="disableButton()"
      >
          <div :class="{'text-left':true,'d-flex flex-row':isOneXTwo}" v-b-tooltip :title="option.message">
            <div v-if="isOneXTwo" class="mr-2 text-truncate">{{option.title}}</div>
            <div v-if="option.point != null">{{pointValue}}</div>
            <div v-else>&nbsp;</div>
            <div :class="{'init':classInit,'odds-change-up':classOddsUp,'odds-change-down':classOddsDown}">
              <odds-value :odds="option.odds" :class="`text-${color}`"/>
            </div>
          </div>
      </b-btn>
  </div>
</template>

<script lang="js">
import oddsValue from './odds-value'
export default {
name: 'event-card-game',
components: {
  oddsValue
},
props: {
  option: Object,
  game: Object,
  event: Object,    
  viewType: String,
  color: String,
},
data (){
  return {
    classOddsUp: false,
    classOddsDown: false,
    classInit: true
  }
},
computed: {
  isOneXTwo(){
    return this.game.options.length > 2;
  },
  pointValue(){
    return (this.game.title.toLowerCase() == 'totals' || this.game.title.toLowerCase() == 'total')
      ? this.option.title.substring(0,1) + ' ' + this.option.point
      : this.option.point
  }
},
beforeDestroy: function(){
  this.$root.$off('updateOptionStatus');
},
mounted() {
  this.$root.$on('updateOptionStatus', (updated) => {
    if(this.option.id == updated.id) {
      let changeStatus = updated.odds > updated.previousOdds
        ? 'up'
        : (updated.odds < updated.previousOdds ? 'down' : '');

      this.option.odds = updated.odds;
      this.classOddsUp = changeStatus == 'up';
      this.classOddsDown = changeStatus == 'down';

      setTimeout(() => { 
        this.classOddsUp = false;
        this.classOddsDown = false;
      }, 3000);
    }
  });
},
methods: {
  onVoting(option){
    if(this.IsLoggedIn) {
      this.$swal({
        title: this.$t('confirmVote', {optionTitle:  option.title}),
        footer: this.GetModalFooter(this.$t('voteFeeMessage')),
        icon: 'info',
        animation: false,
        showCancelButton: true,
        confirmButtonText: this.$t('ok'),
        cancelButtonText: this.$t('cancel')
      }).then((result) => {
        if (result.value) {
          let voteItem = {
              gameOptionId: option.id,
              userId:this.UserId
          };
          this.CallHub({task: 'SaveVote', callback: 'voteSaved', data: JSON.stringify(voteItem)});
        }
      });
    } else {
      this.OpenConnectWalletModal();
    }
  },
  callBetslip: function (selectedOption) {
    selectedOption.isInBetslip = this.GetBetslipIndex(selectedOption.id) > -1;
    let selectedItem = {
      gameOptionId: selectedOption.id,
      odds: selectedOption.odds,
      point: selectedOption.point,
      addToBetslip: !selectedOption.isInBetslip,
      enableBet: selectedOption.enableBet
    };
    this.$root.$emit('addToBetslip', selectedItem);
  },
  disableButton() {
    if(this.viewType==='vote'){
      let voteDeadline = this.$moment.utc(this.event.voteDeadline);
      var currentUtcDate = this.$moment().utc();
      let voted = false;
      this.game.options.forEach(function(option) {
        voted |= option.voted;
      });
      voted = voted == 1 ? true : false;
      return (this.game.gameStatus !== 3 && voteDeadline < currentUtcDate) || voted;
    } else {
      let noCapacity = !this.game.useSharedSeedMoney && (this.GetFloorValue(this.game.seedMoney - this.option.payout, 2)  <= 0);
      return this.game.gameStatus !== 1 || !this.option.enableBet || noCapacity;
    }
      
  }
}
}
</script>